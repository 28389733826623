<template>
  <div id="center-key-figures-conf-component">
    {{/* CHOOSE CENTER */}}

    <div class="col-12 col-md-4 col-lg-6 p-0 pl-3 mb-2 mx-auto">
      <div class="w-100 d-flex justify-content-center">
        <b-dropdown
            class="dropdown-form-dashboard w-50 m-auto position-relative"
            :text="config.center.title"
            ref="dropdown"
            variant="outline-secondary"
        >
          <b-dropdown-item
              v-for="(center) in CenterModule.centers"
              :value="center.id"
              :key="center.id"
              v-on:click="onChangeCenter(center.id)"
          >
            {{ center.city }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    {{/* BLOCK NAV TABS */}}
    <nav v-if="this.config.center.id !== 0">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <a class="nav-item nav-link active">
          <div class="mt-1 p-1">
            Configuration Export
            <span class="text-info text-bold"> {{ (config.center.id) ? ' | ' + config.center.data.city : ''}}</span>
          </div>
        </a>
      </div>
    </nav>
    {{/* TAB CONTENT */}}
    <div class="tab-content" id="nav-tabContent" v-if="this.config.center.id !== 0">
      <b-card class="border-top-0" no-body>
        {{/* BLOCK CONFIG */}}
        <div class="fade show active">
          <div class="mt-3 row">
            <div class="col-12 col-md-4 col-lg-3 p-0 pl-3 mb-2 mx-auto">
              <div class="p-2">
                {{/* CHOOSE YEAR */}}
                <Datepicker
                    v-if="config.center.id"
                    input-class="w-100 text-center date-select"
                    id="date"
                    :minimumView="'year'"
                    :maximumView="'year'"
                    v-model="datepickerData.year"
                    :format="datepickerData.formatDate"
                    :language="datepickerData.language"
                    @input="onChangeDate"
                    placeholder="Choisir une période"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mr-4 mb-4" v-if="config.center.id">
          <button class="btn btn-success" v-on:click="validateConf">Valider la configuration</button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import moment from 'moment'
import { fr } from 'vuejs-datepicker/dist/locale'

export default {
  data() {
    return {
      loader: {
        isLoading: false
      },
      config: {
        center: {
          id: 0,
          title: 'Choisir un centre',
          data: null
        },
        period: {
          date: null,
          isChangeDate: false
        }
      },
      datepickerData: {
        year: null,
        formatDate: 'yyyy',
        language: fr
      }
    }
  },
  created() {
    this.config.period.date = this.currentYear
  },
  methods: {
    /*----------------- VALIDATE CONF ------------------*/
    validateConf()
    {
      this.$emit('emit-validate-conf', {
        selectedCenter: this.config.center,
        periodDate: this.config.period.date
      })
    },
    /*----------------- ON CHANGE CENTER ------------------*/
    onChangeCenter(centerId) {
      this.config.center.id = centerId
      let getCenter = this.$store.getters.getCenterById(centerId)
      this.config.center.data = getCenter
      this.config.center.title = getCenter.city
    },
    /*----------------- ON CHANGE PERIOD ------------------*/
    onChangeDate()
    {
      this.config.period.isChangeDate = true
      this.config.period.date = moment(this.datepickerData.year).format('YYYY')
    },
  },
  computed: {
    currentYear() {
      return moment().format('YYYY')
    },
    ...mapState(['CenterModule']),
  },
  components: {
    Datepicker: () => import('vuejs-datepicker')
  },
}
</script>
